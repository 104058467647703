<template>

  <div>
    <div class="card-body expense-items">
      <!-- Table Container Card -->
      <div class="shadow-sm">
        <b-card
          no-body
          class="mb-0"
        >
          <b-table
            ref="refAllExpenseItemsTable"
            class="position-relative has_padding event_list"
            :items="expenseItems"
            responsive
            :fields="tableColumns"
            show-empty
            no-sort-reset
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-compare-locale="zh"
            :sort-compare-options="{ numeric: true, sensitivity: 'base', localeMatcher: 'best fit' }"
            :sort-compare="sortCompare"
            empty-text="No matching records found"
          >
            <!-- Column: Role -->
            <template #cell(budgetPeriod)="data">
              <span class="text-bold-black">{{ data.item.budgetPeriod.name || '-' }}</span>
              <br>
              <span class="">{{ dateFormat(data.item.budgetPeriod.start) + ' - ' + dateFormat(data.item.budgetPeriod.end) }}</span>
            </template>

            <template #cell(costCenter)="data">
              <span>{{ data.item.costCenter.name }}</span>
            </template>

            <template #cell(expenseCategory)="data">
              <span>
                {{ data.item.expenseCategory.code || '-' }} - {{ data.item.expenseCategory.name }}
              </span>
            </template>

            <template #cell(subBudget)="data">
              <span v-if="canViewThisAction('see-balance', 'BudgetOverview')">{{ data.item.subBudget ? (`$ ${numberFormat(data.item.subBudget.remaining)}`) : '-' }}</span>
              <span v-else>-</span>
            </template>

            <template #cell(allocated)="data">
              <span>$ {{ numberFormat(data.item.allocated) }}</span>
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <span class="">Last edited by {{ budgetExpense.updatedBy.name }} on {{ dateFormatWithTime(budgetExpense.updatedAt) }}</span>
      </div>
    </div>
    <hr
      v-if="budgetExpense.attachments && budgetExpense.attachments.length"
      class="dividerHR"
    >
    <div
      v-if="budgetExpense.attachments && budgetExpense.attachments.length"
      class="card-body attach__block expense-items"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left"
          cols="12"
          md="12"
        >
          <h3>Attachments & Justifications</h3>
        </b-col>
      </b-row>
      <b-row>
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in budgetExpense.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <span class="remove__doc">
              <b-button
                v-if="budgetExpense.status != 'spent'"
                variant="flat-primary"
                @click="removeAttachment(file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
          <div class="doc__desc">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button
                v-if="budgetExpense.status != 'spent'"
                @click="updateDescription(key)"
              >
                Add a description
              </button>
            </span>
            <span
              v-if="file.description"
              class="edit__desc"
            >
              <b-button
                v-if="budgetExpense.status != 'spent'"
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <b-button
            variant="link"
            class="mr-1 md-mb-1"
            :disabled="budgetExpense.status == 'spent'"
            @click="$refs.hiddenAllocationFileInput.click()"
          >
            <feather-icon
              icon="UploadIcon"
              size="20"
              class="mr-50"
            />
            <span
              class="align-middle"
              style="font-weight: 500; font-size: 14px;"
            >
              Upload
            </span>
          </b-button>
          <b-button
            v-if="attachments.length"
            variant="success"
            class="mr-1 md-mb-1"
            @click="uploadFile"
          >
            Save File
          </b-button>
          <input
            ref="hiddenAllocationFileInput"
            type="file"
            multiple
            capture
            accept="image/jpeg, image/png, application/pdf"
            class="d-none"
            @change="setAttachment"
          >
        </b-col>
      </b-row>
    </div>
    <b-modal
      :id="'modal-attachment-description' + uniqueComponentID"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Voucher No"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BRow, BButton, BCol, BImg, BFormTextarea, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { v4 as uuidv4 } from 'uuid'
// import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    BCol,
    BImg,
    BFormTextarea,
    BLink,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    expenseItems: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    budgetExpense: {
      type: [Object, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      // budgetExpense: store.state['pr-detail-store'].budgetExpense,
      // expenseItems: store.state['pr-detail-store'].expenseItems,
      sortBy: 'budgetPeriod',
      sortDesc: true,
      files: [],
      attachments: [],
      description: '',
      attachmentKey: '',
      uniqueComponentID: uuidv4(),
      tableColumns: [
        {
          key: 'budgetPeriod',
          label: 'Budgetary Period',
          sortable: true,
        },
        {
          key: 'expenseCategoryType',
          label: 'Expense Type',
          sortable: true,
          // sortByFormatted: true,
        },
        {
          key: 'costCenter',
          label: 'Cost Centre',
          sortable: true,
        },
        {
          key: 'expenseCategory',
          label: 'Sub-budget (Expense category)',
          sortable: true,
        },
        {
          key: 'subBudget',
          label: 'Balance',
          sortable: true,
        },
        {
          key: 'allocated',
          label: 'Allocated amount',
          sortable: true,
        },
      ],
    }
  },
  //   computed: {
  //     expenseItems() {
  //       return store.state['pr-detail-store'].expenseItems
  //     },
  //     budgetExpense() {
  //       return store.state['pr-detail-store'].budgetExpense
  //     },
  //   },
  methods: {
    canViewThisAction,
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      let a = ''
      let b = ''
      if (key === 'budgetPeriod') {
        a = aRow.budgetPeriod.start
        b = bRow.budgetPeriod.start
      } else if (key === 'expenseCategoryType') {
        a = aRow.expenseCategoryType
        b = bRow.expenseCategoryType
      } else if (key === 'costCenter') {
        a = aRow.costCenter.name
        b = bRow.costCenter.name
      } else if (key === 'expenseCategory') {
        a = aRow.expenseCategory.name
        b = bRow.expenseCategory.name
      } else if (key === 'subBudget') {
        a = aRow.subBudget ? aRow.subBudget.remaining : 0
        b = bRow.subBudget ? bRow.subBudget.remaining : 0
      } else if (key === 'allocated') {
        a = aRow.allocated
        b = bRow.allocated
      }
      if (
        (typeof a === 'number' && typeof b === 'number')
            || (a instanceof Date && b instanceof Date)
      ) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : a > b ? 1 : 0
      }
      return a.localeCompare(b, compareLocale, compareOptions)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(' ')
      }
      return String(value)
    },
    removeAttachment(url) {
      this.$http
        .post('budget/budget/allocation/remove-attachment', { url, budgetExpense: this.budgetExpense._id })
        .then(response => {
          this.$emit('update:budgetExpense', response.data.data)
          // const budgetExpense = response.data.data
          // store.commit('pr-detail-store/update_expense', { budgetExpense })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 5mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('budgetExpense', this.budgetExpense._id)
      this.$http.post('budget/budget/allocation/upload/new-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.$emit('update:budgetExpense', response.data.data)
          // const budgetExpense = response.data.data
          // store.commit('pr-detail-store/update_expense', { budgetExpense })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', `modal-attachment-description${this.uniqueComponentID}`, '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.budgetExpense.attachments[key].description
      this.$root.$emit('bv::show::modal', `modal-attachment-description${this.uniqueComponentID}`, '')
    },
    saveDescription() {
      const url = this.budgetExpense.attachments[this.attachmentKey].data
      this.$http
        .post('budget/budget/allocation/attachment/save-description', { url, description: this.description, budgetExpense: this.budgetExpense._id })
        .then(response => {
          this.$emit('update:budgetExpense', response.data.data)
          // const budgetExpense = response.data.data
          // store.commit('pr-detail-store/update_expense', { budgetExpense })
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
